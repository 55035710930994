import axios from "axios";


const url = process.env.VUE_APP_FUSION_API_URL
const API_KEY = process.env.VUE_APP_FUSION_API_KEY;

// const url = "https://api-staging.3p-logistics.co.uk/"
// const API_KEY = "8b74723d-98fc-4b16-8d2e-27e123f097ff"
export default {
   async getInboundBookings( dateFrom, dateTo) {  
    let fullUrl =  `${url}/inbounds/internal?API-KEY=${API_KEY}&timestamp=${new Date().getTime()}`
    if(dateFrom){
        fullUrl += `&dateSelectedFrom=${dateFrom}`
    }
    if(dateTo){
        fullUrl += `&dateSelectedTo=${dateTo}`
    }
    let res = await axios.get(fullUrl);    
    return res.data.data;
  },
  async cancelInboundBooking( poNumber) {  
    let fullUrl =  `${url}/inbounds`
    let urlencoded = new URLSearchParams();
    urlencoded.append("poNumber", poNumber)
    urlencoded.append("API-KEY", API_KEY)
   
    let res = await axios.delete(fullUrl, {data:urlencoded} );    
    return res.data.data;
  },
 
}