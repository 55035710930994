<template>
  <div class="billingSnap">
    <div class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-1">Inbound Bookings</h1>
        </div>
      </div>
    </div>
    <div class="container">
      <v-card :loading="loading" class="mb-4  pa-4">
        <v-form v-model="valid" class="ma-2" @submit.prevent>
          <v-container>
            <v-row>
              <date-picker @clicked="setDateRange" v-model="loadedDate" :colWidth="5"></date-picker>
              <v-col cols="2">
                <v-btn
                  class="ml-1 mr-1"
                  :loading="loading"
                  :disabled="loading"
                  color="primary"
                  @click="getData()"
                >Submit</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card>
      <div  v-if="data.length > 0">
       <ExportButtons
                  :headers="headers"
                  :data="data"
                  :title="`IB_${this.query.dateShippedFrom}_${this.query.dateShippedTo}`"
                />
        <v-card>
          <v-card-title>
           Inbound Bookings -  {{loadedDate[0]}} to {{loadedDate[1]}}
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="data"
            :items-per-page="5"
            :search="search"
            id="data-table"
          >
            <template v-slot:item.additional_costs="{ item }">
            <v-chip>{{ renderCurrency(item.additional_costs) }}</v-chip>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-btn class="mb-1" color="primary" block small depressed @click="viewInbound(item)">
                    View
                </v-btn >
                <v-btn color="error" small depressed @click="cancelInbound(item)">
                    Cancel
                </v-btn>
            </template>
          </v-data-table>
        </v-card>
        <v-snackbar v-model="snackbar" :timeout="5000">
          {{ text }}
          <v-btn color="blue" text @click="snackbar = false"></v-btn>
        </v-snackbar>
      </div>
    </div>
  </div>
</template>
<script>

import * as XLSX from 'xlsx'

import DatePicker from "../components/forms/DatePicker";
import Reports from "@/services/Reports.js";
import ExportButtons from "@/components/ExportButtons";

export default {
  components: {
    DatePicker, ExportButtons
  },
  data() {
    return {
      title: 'InboundBookings',
      // Snackbar
      snackbar: false,
      text: "",
    
   
      // Search
      search: "",
      // Button Loading
      loading: false,
      // Data
      data: [],
      // Form
      valid: false,
      // Query
      query: {
        dateShippedFrom: "",
        dateShippedTo: ""
      },
    
     
       loadedDate: [],
       baseApiUrl: 'https://api.3p-logistics.co.uk/',
       apiKey: 'process.env.VUE_APP_FUSION_API_KEY',
      // Table
      headers: [
        { text: "PO Number", value: "po_number" },
        { text: "Company", value: "companyName" },
        { text: "Warehouse", value: "site_id" },
        { text: "Date Request", value: "requested_date" },
        { text: "Time Slot", value: "time_slot" },
        { text: "Transit", value: "transit_type" },
        { text: "Container", value: "container_type" },
        { text: "Service", value: "inbound_service" },
        { text: "Cartons", value: "number_cartons" },
        { text: "Pallets", value: "number_pallets" },
        { text: "Lines", value: "Lines" },
        { text: "Line Qty", value: "LineQty" },
        { text: "Volume", value: "Volume" },
        { text: "Weight", value: "Weight" },
        { text: "Carrier Name", value: "carrier_name" },
        { text: "Additional Costs", value: "additional_costs" },
        { text: "Created By", value: "name" },
        { text: "Current Status", value: "current_status" },
        { text: "Created", value: "created_at" },
        { text: "", value: "actions" },
        

       
      ]
    };
  },
  methods: {
    viewInbound(item){
         let url = `${this.baseApiUrl}welcome/customer_file?API-KEY=${this.apiKey}&grnReceipts=${item.Receipt}&delivery=view&companyCode=${item.companyCode}`
         window.open(url, '_blank');
     },
    cancelInbound(item){
         if (confirm(`Are you sure you want to cancel inbound ${item.po_number}?`)){
          Reports.cancelInboundBooking(
            item.po_number
          )
            .then(
              (() => {
                this.loading = false;
                this.snackbar = true;
                this.text = `Inbound has been cancelled.`;
                this.getData();
              })
            )
            .catch(error => {
              this.snackbar = true;
              this.text = `${error.message}`;
              this.loading = false;
              return;
            });
        }
    },
    setDateRange(dates) {
      this.loadedDate = dates;
      this.query.dateShippedFrom = this.loadedDate[0];
      this.query.dateShippedTo = this.loadedDate[1];
    },
     renderCurrency(value) {
      if (value === null) {
        value = 0.0;
      }
      return `£${parseFloat(value).toFixed(2)}`;
    },
    exportToExcel(type, fn, dl) {
      var elt = document.getElementById("data-table");
      var wb = XLSX.utils.table_to_book(elt, { sheet: "Sheet JS" });
      return dl
        ? XLSX.write(wb, { bookType: type, bookSST: true, type: "base64" })
        : XLSX.writeFile(
            wb,
            fn ||
                "inbound_bookings" +
                this.query.dateShippedFrom +
                "_" +
                this.query.dateShippedTo +
                "." +
                (type || "xlsx")
          );
    },
   
  
    async getData() {
      this.loading = true;
    
      Reports.getInboundBookings(
        this.query.dateShippedFrom,
        this.query.dateShippedTo
      )
        .then(
          (data => {
            this.$set(this, "data", data);
            this.loading = false;
            this.snackbar = true;
            this.text = `Successfully Loaded Data between ${this.query.dateShippedFrom} and ${this.query.dateShippedTo}`;
          }).bind(this)
        )
        .catch(error => {
          this.snackbar = true;
          this.text = `${error.message}`;
          this.loading = false;
          return;
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.org-description {
  margin-top: 50px;
}
label {
  display: flex;
  align-items: center;
}
.button-end {
  align-self: flex-end;
}
</style>